<template>
  <el-dialog
    :title="operateProgram.type === 1 ? '添加' : '扣减'"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="800px"
    top="5vh"
    @close="onDialogClose">
    <div class="body">
      <el-table border :data="programList" style="margin-top: 10px;">
        <el-table-column prop="program" :label="$l('ota.program', '程序名')" align="center"></el-table-column>
        <el-table-column prop="number" align="center" :label="$l('ota.remainingNumber', '剩余次数')"></el-table-column>
        <el-table-column prop="operateNumber" :label="$l('ota.applyNumber', '操作次数')" align="center">
          <template slot-scope="scope">
            <el-input v-model="scope.row.operateNumber" type="number"></el-input>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$l("workOrder.confirm", "确认")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        submitLoading: false,
        dialogVisible: false,
        programList: [],
        operateProgram: {
          id: 0,
          program: "",
          number: "",
          type: "",
          operateNumber: "",
        },
      };
    },
    methods: {
      open(id, type) {
        this.dialogVisible = true;
        this.operateProgram.type = type;
        this.$api.getData("otaProgram/" + id).then(res => {
          Object.assign(this.operateProgram, res.data);
          this.programList.push(this.operateProgram);
        }).catch((error) => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      handleSubmit() {
        if (this.operateProgram.operateNumber < 0){
          this.$message.error("操作次数不能为负数");
          return;
        }
        if (!this.operateProgram.operateNumber) {
          this.$message.error(this.$l("ota.numberNotNull", "操作次数不能为空"));
          return;
        }
        if (this.operateProgram.type === 2) {
          if (this.operateProgram.operateNumber > this.operateProgram.number) {
            this.$message.error(this.$l("ota.applyNumberMore", "扣减的次数不能大于剩余次数"));
            this.operateProgram.operateNumber = "";
            return;
          }
        }
        this.submitLoading = true;
        this.$http.post("otaProgram/updateProgram", this.operateProgram).then(res => {
          this.submitLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success");
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
        });
      },
      onDialogClose() {
        this.programList = [];
        this.operateProgram = {
          id: 0,
          program: "",
          number: "",
          type: "",
          operateNumber: "",
        };
      },
    },
  };
</script>

<style lang="scss">
</style>
