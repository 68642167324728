<template>
  <el-dialog
    class="select-dialog"
    title="选择程序"
    :visible.sync="dialogVisible"
    width="80%"
    top="6vh"
    append-to-body
    @close="onDialogClose">
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      :multi-selection="multiSelection"
      url="otaProgram"
      @row-click="handleRowClick"
      @selection-change="handleMultiSelectChange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" v-if="multiSelection" type="primary" @click="multiSelectSure">
          {{ $l("elevator.confirmChoice", "确定选择") }}
        </el-button>
      </template>
      <el-table-column
        prop="program"
        :label="$l('ota.program', '程序名')"
        show-overflow-tooltip
        align="center"></el-table-column>
      <el-table-column
        prop="programType"
        :label="$l('ota.count', '程序类型')"
        align="center"></el-table-column>
      <el-table-column
        prop="softCode"
        :label="$l('ota.program', '软件编码')"
        show-overflow-tooltip
        align="center"></el-table-column>
      <el-table-column
        prop="version"
        :label="$l('ota.program', '版本')"
        show-overflow-tooltip
        align="center"></el-table-column>
      <el-table-column
        prop="softNote"
        :label="$l('ota.program', '软件描述')"
        show-overflow-tooltip></el-table-column>
      <el-table-column
        prop="useNote"
        :label="$l('ota.program', '用途描述')"
        show-overflow-tooltip></el-table-column>
      <el-table-column prop="isActive" label="是否锁定" width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.isActive === false" type="info">
            锁定
          </el-tag>
          <el-tag v-if="scope.row.isActive === true" type="success">
            有效
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="createTime" :label="$l('newMsgSend.sendTime', '生成时间')" align="center"></el-table-column>
      <el-table-column prop="updateTime" :label="$l('newMsgSend.sendTime', '更新时间')" align="center"></el-table-column>
      <el-table-column prop="orgName" :label="$l('newMsgSend.referBizNo', '所属企业')" align="center"></el-table-column>
      <el-table-column :label="$t('common.operation')" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="success" @click="handleSelect(scope.row)">{{ $t("common.select") }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
  </el-dialog>
</template>
<script>

  export default {
    props: {
      multiSelection: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        dialogVisible: false,
        loading: false,
        search: {
          filter: "",
        },
      };
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
      open() {
        this.dialogVisible = true;
        this.$nextTick(() => {
          this.getList(1);
        });
      },
      handleSelect(row) {
        this.$emit("select", row);
        this.dialogVisible = false;
      },
      onDialogClose() {
        this.$copy(this.$data, this.$options.data.call(this));
      },
      handleMultiSelectChange(val) {
        this.multiSelect = val;
      },
      multiSelectSure() {
        this.$emit("multi-select", this.multiSelect);
        this.dialogVisible = false;
      },
      handleRowClick(row, column) {
        if (column && column.label === this.$t("common.operation")) {
          return;
        }
        this.$refs.vmTable.$refs.elTable.toggleRowSelection(row);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
