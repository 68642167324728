<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="80%"
    top="5vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="110px"
        :model="floorDisplay"
        :rules="ruleValidate">
        <el-row>
          <el-col :span="8">
            <el-form-item v-if="floorDisplay.id > 0" :label="$t('floorDisplay.no')">
              {{floorDisplay.no}}
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-col :span="8">
            <el-form-item :label="$t('floorDisplay.name')" prop="name">
              <el-input v-model="floorDisplay.name" :placeholder="$t('common.pleaseEnter')"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row>
          <el-table
            :data="floorDisplay.floorDisplayMappings"
            border
            stripe
            style="width: 100%"
            max-height="400"
          >
            <el-table-column prop="value" :label="$t('elevator.actualFloor')">
              <template slot-scope="scope">
                {{scope.row.value}}
              </template>
            </el-table-column>
            <el-table-column prop="currentFloorCode" :label="$t('elevator.currentFloorCode')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.currentFloorCode"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="faultFloorCode" :label="$t('elevator.faultFloorCode')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.faultFloorCode"></el-input>
              </template>
            </el-table-column>
            <el-table-column prop="displayValue" :label="$t('elevator.displayedFloor')">
              <template slot-scope="scope">
                <el-input v-model="scope.row.displayValue"></el-input>
              </template>
            </el-table-column>
            <el-table-column :label="$t('common.operation')" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                  {{$t("common.remove")}}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
          <div style="margin-top: 10px;float: left">
            <el-button type="text" @click="handleAddFloorClick()"> + {{$t("elevator.addFloorDisPlay")}}</el-button>
          </div>
        </el-row>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" :disabled="saveDisabled" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import ElCol from "element-ui/packages/col/src/col";

  const moduleName = "floorDisplays";

  export default {
    components: {ElCol},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        floorDisplay: {
          id: 0,
          no: "",
          name: "",
          floorDisplayMappings: [],
        },
        ruleValidate: {
          name: [
            {required: true, message: this.$t("floorDisplay.name") + this.$t("common.tip.notNull"), trigger: "blur"},
          ],
        },
      };
    },
    computed: {
      title() {
        return this.floorDisplay.id === 0 ? this.$t("common.add") + " " + this.$t("floorDisplay.floorDisplay") : this.$t("common.edit") + " " + this.$t("floorDisplay.floorDisplay");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.floorDisplay.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.$http.get("floorDisplays/" + this.floorDisplay.id).then(res => {
          console.log(res.data);
          this.floorDisplay = Object.assign(this.floorDisplay, res.data);
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("floorDisplays", this.floorDisplay).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.floorDisplay.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      deleteRow(index) {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          let rows = this.floorDisplay.floorDisplayMappings;
          rows.splice(index, 1);
          for (let i = index; i < this.floorDisplay.floorDisplayMappings.length; i++) {
            this.floorDisplay.floorDisplayMappings[i].value = i + 1;
          }
        });
      },
      handleAddFloorClick() {
        let floor = {
          value: this.floorDisplay.floorDisplayMappings.length + 1,
          displayValue: "",
          currentFloorCode: "",
          faultFloorCode: "",
        };
        this.floorDisplay.floorDisplayMappings.push(floor);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
