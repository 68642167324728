<template>
  <el-dialog
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    title="编辑总次数"
    width="500px"
    top="3vh">
    <div>
      <el-form
        ref="formValidate"
        class="form"
        label-width="70px"
        :model="otaTaskDetail">
        <div class="vm-separate">
          <el-form-item label="总次数" prop="totalNumber" :rules="$rule.notNull">
            <el-input
              v-model="otaTaskDetail.totalNumber"
              type="number"
              :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
      </el-form>
    </div>
    <span slot="footer">
      <el-button @click="dialogVisible = false">{{$t("elevatorNetApply.cancel")}}</el-button>
      <el-button
        :loading="submitLoading"
        type="primary"
        @click="handleSubmit">{{$t("elevatorNetApply.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>
  export default {
    data() {
      return {
        dialogVisible: false,
        submitLoading: false,
        otaTaskDetail: {
          detailId: 0,
          totalNumber: "",
        },
      };
    },
    methods: {
      open(detailId) {
        if (detailId) {
          this.otaTaskDetail.detailId = detailId;
          this.getData();
        }
        this.dialogVisible = true;
      },
      getData() {
        this.$http
          .get("ota/getDetailById/" + this.otaTaskDetail.detailId)
          .then((res) => {
            this.otaTaskDetail.totalNumber = res.data.totalNumber;
          });
      },
      handleSubmit() {
        this.$http.post("ota/updateNumber/" + this.otaTaskDetail.detailId + "/" + this.otaTaskDetail.totalNumber).then(() => {
          this.dialogVisible = false;
          this.submitLoading = false;
          this.$emit("save-success");
          this.$message.success(this.$t("common.tip.settingSuccess"));
        }).catch(error => {
          this.submitLoading = false;
          this.$message.error("保存失败");
        });
      },
    },
  };
</script>

<style lang="scss" scoped>

</style>
