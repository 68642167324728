<template>
  <el-dialog
    :title="otaTask.id > 0 ? '编辑' : '新增'"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="800px"
    top="2vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="70px"
        :model="otaTask">
        <div class="vm-separate">
          <el-form-item label="任务名称" prop="taskName" :rules="$rule.notNull">
            <el-input v-model="otaTask.taskName" :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
          <el-form-item label="任务描述" prop="taskNote">
            <el-input
              v-model="otaTask.taskNote"
              type="textarea"
              :rows="2"
              :placeholder="$t('common.pleaseEnter')"></el-input>
          </el-form-item>
        </div>
        <div v-if="otaTask.id === 0">
          <div style="float: left">
            <el-button type="text" @click="$refs.onSelectProgram.open()">
              <span style="font-size: 16px">选择烧录程序</span>
            </el-button>
            <ota-program-select
              ref="onSelectProgram"
              multi-selection="true"
              @multi-select="onMultiProgramSelect"
              @select="selectProgram"></ota-program-select>
          </div>
          <el-table
            :data="otaTask.programList"
            max-height="200"
            border
            stripe
            style="width: 100%">
            <el-table-column prop="program" label="程序名"></el-table-column>
            <el-table-column prop="programType" label="程序类型"></el-table-column>
            <el-table-column :label="$t('common.operate')" width="120px" align="center">
              <template slot-scope="scope">
                <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                  {{$t("common.remove")}}
                </el-button>
              </template>
            </el-table-column>
          </el-table>
        </div>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button type="primary" :loading="submitLoading" @click="handleSubmit">
        {{$t("common.save")}}
      </el-button>
    </span>
  </el-dialog>
</template>

<script>
  import OtaProgramSelect from "@/views/elevator/elevatorSetting/DIAoOTA/OtaProgramSelect";

  export default {

    components: {OtaProgramSelect},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        otaTask: {
          id: 0,
          taskName: "",
          taskNote: "",
          multiSelect: [],
          programList: [],
          userList: [],
        },
      };
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        if (id > 0) {
          this.otaTask.id = id;
          this.getData(id);
        }
      },
      getData() {
        this.$http
          .get("ota/getById/" + this.otaTask.id)
          .then((res) => {
            this.otaTask = res.data;
          });
      },
      onDialogClose() {
        this.otaTask.programList = [];
        this.otaTask.taskName = "";
        this.otaTask.taskNote = "";
      },
      handleSubmit() {
        if (this.otaTask.id === 0) {
          if (!this.otaTask.programList) {
            this.$message.error("烧录程序不能为空");
          }
        }
        this.$refs.formValidate.validate(valid => {
          if (valid) {
            this.submitLoading = true;
            this.$http.save("ota", this.otaTask)
              .then(() => {
                this.submitLoading = false;
                this.dialogVisible = false;
                this.$emit("save-success");
                this.$message.success(this.$t("common.tip.saveSuccess"));
              })
              .catch(() => {
                this.submitLoading = false;
              });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      onMultiUserSelect(rows) {
        for (let row of rows) {
          let flag = true;
          for (let item of this.otaTask.userList) {
            if (item.id === row.id) {
              flag = false;
            }
          }
          if (flag) {
            this.otaTask.userList.push(row);
          }
        }
      },
      onMultiProgramSelect(rows) {
        for (let row of rows) {
          let flag = true;
          for (let item of this.otaTask.programList) {
            if (item.id === row.id) {
              flag = false;
            }
          }
          if (flag) {
            this.otaTask.programList.push(row);
          }
        }
      },
      selectUser(row) {
        for (let item of this.otaTask.userList) {
          if (item.id === row.id) {
            return;
          }
        }
        this.otaTask.userList.push(row);
      },
      selectProgram(row) {
        for (let item of this.otaTask.programList) {
          if (item.id === row.id) {
            return;
          }
        }
        this.otaTask.programList.push(row);
      },
      deleteRow(index) {
        this.otaTask.programList.splice(index, 1);
      },
      deleteUserRow(index) {
        this.otaTask.userList.splice(index, 1);
      },
    },
  };
</script>

<style scoped>

</style>
