<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="80%"
    top="2vh"
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-form
        ref="formValidate"
        class="form"
        label-width="110px"
        :model="fault"
        :rules="ruleValidate">
        <el-tabs v-model="activeTab">
          <el-tab-pane :label="$t('faultTemplate.baseInfo')" name="first">
            <el-row>
              <el-col :span="12">
                <el-form-item v-if="fault.id > 0" :label="$t('faultTemplate.no')">
                  {{fault.faultNO}}
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('elevator.manufactured')" prop="companyId">
                  <el-input v-model="fault.companyName" :placeholder="$t('common.pleaseSelect')" readonly>
                    <el-button slot="append" icon="el-icon-search" @click="$refs.selectCreateCom.open()"></el-button>
                  </el-input>
                  <company-select ref="selectCreateCom" @select="onSelectCreateCom"></company-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('faultTemplate.name')" prop="desc">
                  <el-input v-model="fault.desc" :placeholder="$t('common.pleaseEnter')"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row v-if="userName === 'superAdmin' || userName === 'admin'">
              <el-col :span="12">
                <el-form-item :label="$t('faultTemplate.mark')">
                  <el-select v-model="fault.faultMark" placeholder="请选择" clearable style="width: 100%">
                    <el-option value="step" label="新时达"></el-option>
                    <el-option value="monarch" label="默纳克"></el-option>
                    <el-option value="tke" label="蒂升"></el-option>
                    <el-option value="aolida_inovance" label="奥立达_汇川"></el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="12">
                <el-form-item :label="$t('faultTemplate.remark')" prop="note">
                  <el-input
                    v-model="fault.note"
                    type="textarea"
                    :rows="2"
                    :placeholder="$t('common.pleaseEnter')"></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane :label="$t('faultTemplate.type1') + ' ' + $t('faultTemplate.faultCode')" name="second">
            <el-row>
              <el-table
                :data="faultSettings1"
                border
                stripe
                style="width: 100%"
                max-height="500"
                @selection-change="handleSelectionChange1">
                <el-table-column type="selection" width="50">
                </el-table-column>
                <el-table-column :label="$t('faultTemplate.SerialNo')" width="50">
                  <template slot-scope="scope">
                    {{scope.$index + 1}}
                  </template>
                </el-table-column>
                <el-table-column prop="code" :label="$t('faultTemplate.faultCode')">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.code" :disabled="scope.row.id > 0"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="desc" :label="$t('faultTemplate.faultType')">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.desc"></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.operation')" width="200" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow1(scope.$index)">
                      {{$t("common.remove")}}
                    </el-button>
                    <el-button
                      v-if="scope.$index === 0"
                      type="text"
                      class="el-icon-minus"
                      style="margin-left: 20px"></el-button>
                    <el-button
                      v-if="scope.$index !== 0"
                      type="text"
                      class="el-icon-caret-top"
                      style="margin-left: 20px"
                      @click="handleUp1(scope.$index)"></el-button>
                    <el-button
                      v-if="scope.$index === faultSettings1.length - 1"
                      type="text"
                      class="el-icon-minus"
                      style="margin-left: 20px"></el-button>
                    <el-button
                      v-if="scope.$index !== faultSettings1.length - 1"
                      type="text"
                      class="el-icon-caret-bottom"
                      style="margin-left: 20px"
                      @click="handleDown1(scope.$index)"></el-button>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.operation')" width="200" align="center">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.id" type="primary" @click="$refs.solutionSetting.open(scope.row)">
                      {{$t("faultTemplate.solution")}}
                    </el-button>
                    <el-button v-if="scope.row.id" type="primary" @click="$refs.maintReco.open(scope.row)">
                      {{$t("faultTemplate.maintReco")}}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <el-row>
              <div style="margin-top: 10px;float: left">
                <el-button type="text" @click="handleAddClick1()"> + {{$t("faultTemplate.addaRow")}}</el-button>
                <el-button type="text" @click="handleDeleteClick1()">
                  - {{$t("faultTemplate.deleteSelectedRow")}}
                </el-button>
                <el-button type="text" @click="handleCommon()">
                  {{$t("faultTemplate.generatingGeneralFaultCode")}}
                </el-button>
                <el-button type="text" @click="$refs.selectFault.open()">
                  {{$t("common.select") + $t("faultTemplate.faultTemplate")}}
                </el-button>
                <fault-select ref="selectFault" @select="onselectFault"></fault-select>
              </div>
            </el-row>
          </el-tab-pane>
          <el-tab-pane :label="$t('faultTemplate.type2') + ' ' + $t('faultTemplate.faultCode')" name="third">
            <el-row>
              <el-table
                :data="faultSettings2"
                border
                stripe
                style="width: 100%"
                max-height="500"

                @selection-change="handleSelectionChange2">
                <el-table-column type="selection" width="50">
                </el-table-column>
                <el-table-column :label="$t('faultTemplate.SerialNo')" width="50">
                  <template slot-scope="scope">
                    {{scope.$index + 1}}
                  </template>
                </el-table-column>
                <el-table-column prop="code" :label="$t('faultTemplate.faultCode')">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.code" :disabled="scope.row.id > 0"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="desc" :label="$t('faultTemplate.faultType')">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.desc"></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.operation')" width="200" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow2(scope.$index)">
                      {{$t("common.remove")}}
                    </el-button>
                    <el-button
                      v-if="scope.$index === 0"
                      type="text"
                      class="el-icon-minus"
                      style="margin-left: 20px"></el-button>
                    <el-button
                      v-if="scope.$index !== 0"
                      type="text"
                      class="el-icon-caret-top"
                      style="margin-left: 20px"
                      @click="handleUp2(scope.$index)"></el-button>
                    <el-button
                      v-if="scope.$index === faultSettings2.length - 1"
                      type="text"
                      class="el-icon-minus"
                      style="margin-left: 20px"></el-button>
                    <el-button
                      v-if="scope.$index !== faultSettings2.length - 1"
                      type="text"
                      class="el-icon-caret-bottom"
                      style="margin-left: 20px"
                      @click="handleDown2(scope.$index)"></el-button>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.operation')" width="110" align="center">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.id" type="primary" @click="$refs.solutionSetting.open(scope.row)">
                      {{$t("faultTemplate.solution")}}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <el-row>
              <div style="margin-top: 10px;float: left">
                <el-button type="text" @click="handleAddClick2()"> + {{$t("faultTemplate.addaRow")}}</el-button>
                <el-button type="text" @click="handleDeleteClick2()">
                  - {{$t("faultTemplate.deleteSelectedRow")}}
                </el-button>
              </div>
            </el-row>
          </el-tab-pane>
          <el-tab-pane :label="$l('faultTemplate.type3', '门机') + ' ' + $t('faultTemplate.faultCode')" name="fourth">
            <el-row>
              <el-table
                :data="faultSettings3"
                border
                stripe
                style="width: 100%"
                max-height="500"
                @selection-change="handleSelectionChange3">
                <el-table-column type="selection" width="50">
                </el-table-column>
                <el-table-column :label="$t('faultTemplate.SerialNo')" width="50">
                  <template slot-scope="scope">
                    {{scope.$index + 1}}
                  </template>
                </el-table-column>
                <el-table-column prop="code" :label="$t('faultTemplate.faultCode')">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.code" :disabled="scope.row.id > 0"></el-input>
                  </template>
                </el-table-column>
                <el-table-column prop="desc" :label="$t('faultTemplate.faultType')">
                  <template slot-scope="scope">
                    <el-input v-model="scope.row.desc"></el-input>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.operation')" width="200" align="center">
                  <template slot-scope="scope">
                    <el-button type="text" @click.native.prevent="deleteRow3(scope.$index)">
                      {{$t("common.remove")}}
                    </el-button>
                    <el-button
                      v-if="scope.$index === 0"
                      type="text"
                      class="el-icon-minus"
                      style="margin-left: 20px"></el-button>
                    <el-button
                      v-if="scope.$index !== 0"
                      type="text"
                      class="el-icon-caret-top"
                      style="margin-left: 20px"
                      @click="handleUp3(scope.$index)"></el-button>
                    <el-button
                      v-if="scope.$index === faultSettings3.length - 1"
                      type="text"
                      class="el-icon-minus"
                      style="margin-left: 20px"></el-button>
                    <el-button
                      v-if="scope.$index !== faultSettings3.length - 1"
                      type="text"
                      class="el-icon-caret-bottom"
                      style="margin-left: 20px"
                      @click="handleDown3(scope.$index)"></el-button>
                  </template>
                </el-table-column>
                <el-table-column :label="$t('common.operation')" width="110" align="center">
                  <template slot-scope="scope">
                    <el-button v-if="scope.row.id" type="primary" @click="$refs.solutionSetting.open(scope.row)">
                      {{$t("faultTemplate.solution")}}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
            </el-row>
            <el-row>
              <div style="margin-top: 10px;float: left">
                <el-button type="text" @click="handleAddClick3()"> + {{$t("faultTemplate.addaRow")}}</el-button>
                <el-button type="text" @click="handleDeleteClick3()">
                  - {{$t("faultTemplate.deleteSelectedRow")}}
                </el-button>
              </div>
            </el-row>
          </el-tab-pane>
        </el-tabs>
      </el-form>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
    <solution-setting-edit ref="solutionSetting"></solution-setting-edit>
    <maint-reco-edit ref="maintReco"></maint-reco-edit>
  </el-dialog>
</template>

<script>
  import ElCol from "element-ui/packages/col/src/col";
  import CompanySelect from "../company/CompanySelect.vue";
  import FaultSelect from "../fault/FaultSelect.vue";
  import SolutionSettingEdit from "./SolutionSettingEdit";
  import MaintRecoEdit from "@/views/fault/MaintRecoEdit";
  import auth from "@/util/auth";

  const moduleName = "faults";

  export default {
    components: {MaintRecoEdit, ElCol, CompanySelect, FaultSelect, SolutionSettingEdit},
    data() {
      return {
        userName: auth.getUsername(),
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        activeTab: "first",
        faultSettings1: [],
        faultSettings2: [],
        faultSettings3: [],
        multipleSelection1: [],
        multipleSelection2: [],
        multipleSelection3: [],
        fault: {
          id: 0,
          faultType: 10,
          companyId: "",
          companyName: "",
          faultNO: "",
          desc: "",
          note: "",
          faultMark: "",
          faultSettings: [],
        },
        ruleValidate: {
          desc: [
            {required: true, message: this.$t("faultTemplate.name") + this.$t("common.tip.notNull"), trigger: "blur"},
          ],
          companyId: [
            {
              required: true,
              message: this.$t("elevator.manufactured") + this.$t("common.tip.notNull"),
              trigger: "blur",
            },
          ],
        },
      };
    },
    computed: {
      title() {
        return this.fault.id === 0 ? this.$t("common.add") + " " + this.$t("faultTemplate.faultTemplate") : this.$t("common.edit") + " " + this.$t("faultTemplate.faultTemplate");
      },
    },
    methods: {
      open(id) {
        this.dialogVisible = true;
        this.fault.id = id;
        if (id > 0) {
          this.getData();
        }
      },
      getData() {
        this.$http.get("faults/" + this.fault.id).then(res => {
          this.fault = Object.assign(this.fault, res.data);
          console.log(this.fault);
          for (let item of this.fault.faultSettings) {
            if (item.faultType === 20) {
              this.faultSettings2.push(item);
            } else if (item.faultType === 30) {
              this.faultSettings3.push(item);
            } else {
              this.faultSettings1.push(item);
            }
          }
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      onDialogClose() {
        this.faultSettings1 = [];
        this.faultSettings2 = [];
        this.faultSettings3 = [];
        this.multipleSelection1 = [];
        this.multipleSelection2 = [];
        this.multipleSelection3 = [];
        this.saveDisabled = false;
        this.contentLoading = false;
        this.$refs.formValidate.resetFields();
        this.$copy(this.$data, this.$options.data.call(this), "ruleValidate");
      },
      handleSubmit() {
        if (this.checkRepeat()) {
          this.$message.error(this.$t("faultTemplate.type1") + this.$t("faultTemplate.faultCode") + this.$t("faultTemplate.duplicateFaultCode"));
          return;
        }
        this.$refs.formValidate.validate((valid) => {
          if (valid) {
            this.fault.faultSettings = [];
            this.fault.faultSettings = this.faultSettings1.concat(this.faultSettings2).concat(this.faultSettings3);
            this.submitLoading = true;
            this.$http.save("faults", this.fault).then(res => {
              this.submitLoading = false;
              this.dialogVisible = false;
              this.$emit("save-success", this.fault.id === 0);
              this.$message.success(this.$t("common.tip.saveSuccess"));
            }).catch(error => {
              this.submitLoading = false;
              this.$message.error(this.$t("common.tip.saveError") + "," + error.response.data.message);
            });
          } else {
            this.$message.error(this.$t("common.tip.submitError") + "!");
          }
        });
      },
      checkRepeat() {
        let nary = [];
        for (let item of this.faultSettings1) {
          nary.push(item.code);
        }
        nary = nary.sort();
        for (let i = 0; i < nary.length - 1; i++) {
          if (nary[i] === nary[i + 1]) {
            return true;
          }
        }
      },
      deleteRow1(index) {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.faultSettings1.splice(index, 1);
        });
      },
      deleteRow2(index) {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.faultSettings2.splice(index, 1);
        });
      },
      deleteRow3(index) {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.faultSettings3.splice(index, 1);
        });
      },
      handleSelectionChange1(val) {
        this.multipleSelection1 = val;
      },
      handleSelectionChange2(val) {
        this.multipleSelection2 = val;
      },
      handleSelectionChange3(val) {
        this.multipleSelection3 = val;
      },
      handleDeleteClick1() {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          for (let item of this.multipleSelection1) {
            for (let i = 0; i < this.faultSettings1.length; i++) {
              if (item.index === this.faultSettings1[i].index) {
                this.faultSettings1.splice(i, 1);
                i--;
                break;
              }
            }
          }
        });
      },
      handleAddClick1() {
        let faultSetting = {index: this.faultSettings1.length, faultType: 10, code: "", desc: ""};
        this.faultSettings1.push(faultSetting);
      },
      handleDeleteClick2() {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          for (let item of this.multipleSelection2) {
            for (let i = 0; i < this.faultSettings2.length; i++) {
              if (item.index === this.faultSettings2[i].index) {
                this.faultSettings2.splice(i, 1);
                i--;
                break;
              }
            }
          }
        });
      },
      handleAddClick2() {
        let faultSetting = {findex: this.faultSettings2.length, faultType: 20, code: "", desc: ""};
        this.faultSettings2.push(faultSetting);
      },
      handleDeleteClick3() {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          for (let item of this.multipleSelection3) {
            for (let i = 0; i < this.faultSettings3.length; i++) {
              if (item.index === this.faultSettings3[i].index) {
                this.faultSettings3.splice(i, 1);
                i--;
                break;
              }
            }
          }
        });
      },
      handleAddClick3() {
        let faultSetting = {findex: this.faultSettings3.length, faultType: 30, code: "", desc: ""};
        this.faultSettings3.push(faultSetting);
      },
      onSelectCreateCom(row) {
        this.fault.companyId = row.id;
        this.fault.companyName = row.name;
      },
      onselectFault(row) {
        let faultId = row.id;
        this.getFaultTemplate(faultId);
      },
      getFaultTemplate(faultId) {
        this.$http.get("faults/" + faultId).then(res => {
          let data = res.data;
          this.faultSettings1 = data.faultSettings;
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      handleCommon() {
        this.contentLoading = true;
        let faultId = 0;
        if (this.$i18n.isCn) {
          faultId = 453;
        } else {
          faultId = 454;
        }
        this.$http.get("faults/common/" + faultId).then(res => {
          this.faultSettings1 = res.data;
          this.contentLoading = false;
          this.saveDisabled = false;
        }).catch((error) => {
          this.contentLoading = false;
          if (error.response) {
            this.$message.error(this.$t("common.tip.getDataError") + "，" + error.response.data.message);
          }
        });
      },
      handleDown1(index) {
        let temp = this.faultSettings1[index];
        this.faultSettings1.splice(index, 1);
        this.faultSettings1.splice(index + 1, 0, temp);
      },
      handleUp1(index) {
        let temp = this.faultSettings1[index];
        this.faultSettings1.splice(index, 1);
        this.faultSettings1.splice(index - 1, 0, temp);
      },
      handleDown2(index) {
        let temp = this.faultSettings2[index];
        this.faultSettings2.splice(index, 1);
        this.faultSettings2.splice(index + 1, 0, temp);
      },
      handleUp2(index) {
        let temp = this.faultSettings2[index];
        this.faultSettings2.splice(index, 1);
        this.faultSettings2.splice(index - 1, 0, temp);
      },
      handleDown3(index) {
        let temp = this.faultSettings3[index];
        this.faultSettings3.splice(index, 1);
        this.faultSettings3.splice(index + 1, 0, temp);
      },
      handleUp3(index) {
        let temp = this.faultSettings3[index];
        this.faultSettings3.splice(index, 1);
        this.faultSettings3.splice(index - 1, 0, temp);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
