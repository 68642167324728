<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="faults">
      <template slot="adSearch">
        <vm-search :label="$t('maintWorkOrder.filter')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button v-if="editAuth" class="addBtn" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
        <el-button @click="handleEditDefault(453)">
          {{ $t("faultTemplate.editDefaultFaultTemplate") }}
        </el-button>
        <el-button @click="handleEditDefault(454)">
          {{ $t("faultTemplate.editDefaultFaultTemplateEn") }}
        </el-button>
      </template>
      <el-table-column prop="faultNO" :label="$t('faultTemplate.no')" width="120" align="center"></el-table-column>
      <el-table-column v-if="showOrgId" prop="companyName" :label="$t('elevator.manufactured')"></el-table-column>
      <el-table-column prop="desc" :label="$t('faultTemplate.name')"></el-table-column>
      <el-table-column prop="isBuiltIn" :label="$t('faultTemplate.isBuiltIn')" width="100" align="center">
        <template slot-scope="scope">
          <span v-if="$i18n.isCn">
            <el-tag type="success" v-if="scope.row.isBuiltIn">是</el-tag>
            <el-tag type="info" v-else>否</el-tag>
          </span>
          <span v-else>
            <el-tag type="success" v-if="scope.row.isBuiltIn">Yes</el-tag>
            <el-tag type="info" v-else>No</el-tag>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="faultMark" label="MARK" align="center" :width="$l('200', '100')"></el-table-column>
      <el-table-column prop="elevCount" :label="$t('company.elevCount')" align="center" :width="$l('200', '100')"></el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" width="100" align="center" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="handleEdit(scope.row)">{{ $t("common.edit") }}</el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">{{ $t("common.delete")
            }}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(-1)"></edit-dialog>
  </div>
</template>
<script>
import EditDialog from "./FaultEdit.vue";
import auth from "@/util/auth";

export default {
  components: { EditDialog },
  data() {
    return {
      showOrgId: auth.getUsername() === "admin" || auth.getUsername() === "superAdmin",
      editAuth: this.$auth(19),
      loading: false,
      search: {
        filter: "",
      },
    };
  },
  mounted() {
    this.getList(1);
  },
  methods: {
    getList(pageNum) {
      this.loading = false;
      this.$refs.vmTable.getList(pageNum);
    },
    handleAdd() {
      this.$refs.editPage.open(0);
    },
    handleEdit(row) {
      this.$refs.editPage.open(row.id);
    },
    handleEditDefault(id) {
      this.$refs.editPage.open(id);
    },
    handleDelete(row) {
      this.$confirm(this.$t("common.delete") + " " + this.$t("faultTemplate.faultTemplateNo") + " " + row.faultNO + "," + this.$t("common.isDelete") + "?", this.$t("common.tips"), {
        confirmButtonText: this.$t("common.confirm"),
        cancelButtonText: this.$t("common.cancel"),
        type: "warning",
      }).then(() => {
        this.$http.delete("faults/" + row.id).then(res => {
          this.getList(-1);
          this.$message.success(this.$t("common.tip.deleteSuccess"));
        }).catch(() => {
          this.$message.error(this.$t("common.tip.deleteError"));
        });
      });
    },
  },
};
</script>
<style lang="scss" scoped></style>
