<template>
  <div>
    <vm-table
      ref="vmTable"
      v-loading="loading"
      :filter.sync="search"
      url="elevatorUsers"
      @clear="clearDateRange">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" style="width: 150px" clearable></el-input>
        </vm-search>
        <vm-search :label="$l('maintWorkOrder.status', '状态')">
          <el-select v-model="search.status" style="width: 100%" clearable :placeholder="$t('common.pleaseSelect')">
            <el-option v-for="item in statusList" :key="item.value" :label="item.label" :value="item.value"></el-option>
          </el-select>
          <vm-search :label="$l('newMsgSend.sendTime', '授权时间')">
            <el-date-picker
              v-model="dateRange"
              value-format="yyyy-MM-dd HH:mm:ss"
              type="datetimerange"
              :unlink-panels="true"
              :range-separator="$t('common.to')"
              :start-placeholder="$t('common.startDate')"
              :end-placeholder="$t('common.endDate')"
              :default-time="['00:00:00', '23:59:59']"
              @change="handleDateChange">
            </el-date-picker>
          </vm-search>
        </vm-search>
      </template>
      <template slot="toolbar">
        <el-button class="addBtn" @click="$refs.editPage.open(0)">
          {{ $l("elevatorNetApply.add", "+ 新增") }}
        </el-button>
      </template>
      <el-table-column prop="elevatorNo" :label="$t('elevator.no')" width="120" align="center"></el-table-column>
      <el-table-column prop="elevatorName" show-overflow-tooltip :label="$t('elevator.name')" width="120"></el-table-column>
      <el-table-column prop="useUnitName" show-overflow-tooltip :label="$t('elevator.useUnit')" width="120"></el-table-column>
      <el-table-column prop="userName" :label="$t('user.username')" width="150"></el-table-column>
      <el-table-column prop="name" :label="$t('user.name')" width="160" show-overflow-tooltip></el-table-column>
      <el-table-column prop="mobile" :label="$t('user.phone')" width="110" align="center"></el-table-column>
      <el-table-column prop="displayFloors" :label="$l('elevator.authorizedFloor','授权楼层')" show-overflow-tooltip></el-table-column>
      <el-table-column prop="startTime" :label="$l('elevator.authorizedStart','授权开始时间')" width="140" align="center"></el-table-column>
      <el-table-column prop="endTime"  :label="$l('elevator.authorizedEnd','授权结束时间')" width="140" align="center"></el-table-column>
      <el-table-column prop="status" :label="$l('maintWorkOrder.status','状态')" width="100" align="center">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.status === 1" type="success">
            {{$l("camera.efficient", "有效")}}
          </el-tag>
          <el-tag v-if="scope.row.status === 0" type="warning">
            {{$l("deviceGateway.invalid", "无效")}}
          </el-tag>
        </template>
      </el-table-column>
      <el-table-column v-if="editAuth" :label="$t('common.operation')" align="center" width="100">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.editPage.open(scope.row.id)">
            {{ $l("elevatorNetApply.edit", "编辑") }}
          </el-button>
          <el-button type="text" class="operateDelBtn" @click="handleDelete(scope.row)">
            {{ $t("common.delete")}}
          </el-button>
        </template>
      </el-table-column>
    </vm-table>
    <edit-dialog ref="editPage" @save-success="getList(1)"></edit-dialog>
  </div>
</template>
<script>
  import EditDialog from "./ElevatorUserEdit.vue";

  export default {
    components: { EditDialog },
    data() {
      return {
        editAuth: this.$auth(108),
        loading: false,
        total: 0,
        dateRange: [],
        search: {
          filter: "",
          status: "",
          startTime: "",
          endTime: "",
        },
        statusList: [
          { label: this.$l("camera.efficient", "有效"), value: 1 },
          { label: this.$l("deviceGateway.invalid", "无效"), value: 0 },
        ]
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageIndex) {
        this.loading = false;
        this.$refs.vmTable.getList(pageIndex);
      },
      handleDateChange(dateRange) {
        if (dateRange != null) {
          this.search.startTime = dateRange[0];
          this.search.endTime = dateRange[1];
        } else {
          this.search.startTime = "";
          this.search.endTime = "";
        }
      },
      clearDateRange() {
        this.dateRange = [];
      },
      handleDelete(row) {
        this.$confirm(this.$t("common.isDelete") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.$api.deleteById("elevatorUsers", row.id).then(res => {
            this.getList(this.currentPage);
            this.$message.success(this.$t("common.tip.deleteSuccess"));
          }).catch((error) => {
            if (error.response) {
              this.$message.error(this.$t("common.tip.deleteError") + "," + error.response.data.message);
            }
          });
        });
      },
      handleClick(row) {
      },
      handleSearch(event) {
        this.filter = event;
        this.getList(1);
      },
      handleAdd() {
        this.$refs.editPage.open(0);
      },
      changePage(pageIndex) {
        this.getList(pageIndex);
      },
    },
  };
</script>
<style lang="scss" scoped></style>
