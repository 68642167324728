<template>
  <div>
    <vm-table ref="vmTable" v-loading="loading" :filter.sync="search" url="otaUserProgram">
      <template slot="adSearch">
        <vm-search :label="$l('maintWorkOrder.filter', '关键字检索')">
          <el-input v-model.trim="search.filter" :placeholder="$t('common.search')" clearable></el-input>
        </vm-search>
      </template>
      <el-table-column prop="username" :label="$l('ota.account', '账号')" align="center"></el-table-column>
      <el-table-column prop="name" :label="$l('ota.name', '姓名')" align="center"></el-table-column>
      <el-table-column prop="programName" :label="$l('ota.program', '程序')" align="center"></el-table-column>
      <el-table-column prop="number" :label="$l('ota.burningTimes', '可烧录次数')" align="center"></el-table-column>
      <el-table-column :label="$l('elevatorNetApply.operate', '操作')" align="center" width="90" fixed="right">
        <template slot-scope="scope">
          <el-button type="text" @click="$refs.operate.open(scope.row.otaUserId,scope.row.programId)">{{$l('ota.operateRecord', '操作记录')}}</el-button>
        </template>
      </el-table-column>
    </vm-table>
    <ota-operate-list ref="operate"></ota-operate-list>
  </div>
</template>
<script>
  import OtaOperateList from "@/views/elevator/elevatorSetting/DIAoOTA/otaOperate/OtaOperateList";

  export default {
    components: {OtaOperateList},
    data() {
      return {
        refreshLoading: false,
        loading: true,
        search: {
          filter: "",
        },
      };
    },
    mounted() {
      this.getList(1);
    },
    methods: {
      getList(pageNum) {
        this.loading = false;
        this.$refs.vmTable.getList(pageNum);
      },
    },
  };
</script>
