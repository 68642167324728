<template>
  <el-dialog
    :title="$l('ota.applyDetails','申请明细')"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="500px"
    top="5vh"
    @close="onDialogClose">
    <div class="body">
      <div>
        <div>
          {{$l("ota.applyProgram", "申请程序")}}：<span>{{program.program}}</span>
        </div>
<!--        <div style="margin-top: 10px">-->
<!--          {{$l("ota.remainingNumber", "剩余次数")}}：<span>{{program.number}}</span>-->
<!--        </div>-->
<!--        <div style="margin-top: 10px">-->
<!--          {{$l("ota.currentNumber", "当前可烧录次数")}}：<span v-if="userProgram.number">{{userProgram.number}}</span>-->
<!--          <span v-else>0</span>-->
<!--        </div>-->
      </div>
      <div>
        <el-divider></el-divider>
        <div>
          {{$l("ota.applicant", "申请人")}}：<span>{{apply.name}}</span>
        </div>
        <div style="margin-top: 10px">
          {{$l("ota.applyNumber", "申请次数")}}：<span>{{apply.applyNumber}}</span>
        </div>
        <div style="margin-top: 10px">
          {{$l("ota.note", "申请备注")}}：<span>{{apply.note}}</span>
        </div>
        <div style="margin-top: 10px">
          {{$l("ota.approvalStatus", "审批状态")}}：
          <el-tag v-if="apply.applyState === 1" type="info">{{$l("ota.applying", "申请中")}}</el-tag>
          <el-tag v-if="apply.applyState === 2" type="success">{{$l("ota.approved", "已同意")}}</el-tag>
          <el-tag v-if="apply.applyState === 3" type="danger">{{$l("ota.rejected", "已拒绝")}}</el-tag>
        </div>
        <div style="margin-top: 10px">
          {{$l("ota.applyTime", "申请时间")}}：<span>{{apply.applyTime}}</span>
        </div>
        <div v-if="apply.applyState === 1">
          <div style="margin-top: 10px">
            <label>{{$l("ota.rejectReason", "审批意见")}}：</label>
            <el-input v-model="apply.refuseReason" type="textarea" style="margin-top: 10px"></el-input>
          </div>
        </div>
      </div>
      <div v-if="apply.applyState === 2">
        <el-divider></el-divider>
        <div>
          {{$l("ota.approver", "审批人")}}：<span>{{apply.acceptName}}</span>
        </div>
        <div style="margin-top: 10px">
          {{$l("ota.approverTime", "审批时间")}}：<span>{{apply.finishTime}}</span>
        </div>
        <div style="margin-top: 10px">
          {{$l("ota.rejectReason", "审批意见")}}：<span>{{apply.refuseReason}}</span>
        </div>
      </div>
      <div v-if="apply.applyState === 3">
        <el-divider></el-divider>
        <div>
          {{$l("ota.rejectPeople", "拒绝人")}}：<span>{{apply.acceptName}}</span>
        </div>
        <div style="margin-top: 10px">
          {{$l("ota.rejectTime", "拒绝时间")}}：<span>{{apply.finishTime}}</span>
        </div>
        <div style="margin-top: 10px">
          {{$l("ota.rejectReason", "审批意见")}}：<span>{{apply.refuseReason}}</span>
        </div>
      </div>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button v-if="apply.applyState === 1" type="danger" :loading="refuseLoading"
                 @click="refuse">{{$l("ota.reject", "拒绝")}}</el-button>
      <el-button
        v-if="apply.applyState === 1"
        type="primary"
        :loading="applyLoading"
        @click="agree()">{{$l("ota.pass", "通过")}}</el-button>
      <el-button v-if="apply.applyState !== 1" type="primary"
                 @click="dialogVisible = false">{{$l("ota.closure", "关闭")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    data() {
      return {
        refuseLoading: false,
        applyLoading: false,
        dialogVisible: false,
        program: {
          id: 0,
          program: "",
          number: "",
        },
        apply: {
          id: 0,
          name: "",
          userId: "",
          applyTime: "",
          applyNumber: "",
          note: "",
          applyState: "",
          acceptName: "",
          refuseReason: "",
          finishTime: "",
        },
        userProgram: {
          id: 0,
          userId: "",
          programId: "",
          number: 0,
        },
      };
    },
    methods: {
      open(row) {
        this.dialogVisible = true;
        Object.assign(this.apply, row);
        this.$api.getData("otaProgram/" + row.programId).then(res => {
          this.program = res.data;
        }).catch((error) => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
        this.$api.getData("otaUserProgram/" + row.userId + "/" + row.programId).then(res => {
          this.userProgram = res.data;
        }).catch((error) => {
          this.$message.error(this.$t("common.tip.getDataError"));
        });
      },
      refuse() {
        this.refuseLoading = true;
        this.$http.post("otaApply/refuse", this.apply).then(res => {
          this.refuseLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success");
          this.$message.success(this.$t("common.tip.saveSuccess"));
        }).catch(error => {
          this.refuseLoading = false;
          this.$t("common.tip.operationFailure");
        });
      },
      agree() {
        // if (this.apply.applyNumber > this.program.number) {
        //   this.$message.error(this.$l("ota.applyNumberMore", "申请次数不能大于剩余次数"));
        //   return;
        // }
        this.applyLoading = true;
        this.$http.post("otaApply/agree", this.apply).then(res => {
          this.applyLoading = false;
          this.dialogVisible = false;
          this.$emit("save-success");
          this.$message.success(this.$t("common.tip.operationSuccess"));
        }).catch(error => {
          this.applyLoading = false;
          this.$t("common.tip.operationFailure");
        });
      },
      onDialogClose() {
        this.programList = [];
        this.applyProgram = {
          id: 0,
          program: "",
          number: "",
          applyNumber: "",
        };
      },
    },
  };
</script>

<style lang="scss">
</style>
