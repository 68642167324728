<template>
  <el-dialog
    :title="title"
    :close-on-click-modal="false"
    :visible.sync="dialogVisible"
    width="80%"
    top="5vh"
    append-to-body
    @close="onDialogClose">
    <div v-loading="contentLoading" class="body">
      <el-row>
        <el-table
          :data="solutions"
          border
          stripe
          style="width: 100%"
          max-height="300"

          @selection-change="handleSelectionChange">
          <el-table-column type="selection" width="50">
          </el-table-column>
          <el-table-column prop="reason" :label="$t('faultTemplate.reason')">
            <template slot-scope="scope">
              <el-input v-model="scope.row.reason"></el-input>
            </template>
          </el-table-column>
          <el-table-column prop="solution" :label="$t('faultTemplate.solution')">
            <template slot-scope="scope">
              <el-input v-model="scope.row.solution"></el-input>
            </template>
          </el-table-column>
          <el-table-column :label="$t('common.operation')" width="200" align="center">
            <template slot-scope="scope">
              <el-button type="text" @click.native.prevent="deleteRow(scope.$index)">
                {{$t("common.remove")}}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-row>
      <el-row>
        <div style="margin-top: 10px;float: left">
          <el-button type="text" @click="handleAddClick()"> + {{$t("faultTemplate.addaRow")}}</el-button>
          <el-button type="text" @click="handleDeleteClick()"> - {{$t("faultTemplate.deleteSelectedRow")}}</el-button>
        </div>
      </el-row>
    </div>
    <span slot="footer" class="dialog-footer">
      <el-button @click="dialogVisible = false">{{$t("common.cancel")}}</el-button>
      <el-button
        type="primary"
        :loading="submitLoading"
        :disabled="saveDisabled"
        @click="handleSubmit">{{$t("common.save")}}</el-button>
    </span>
  </el-dialog>
</template>

<script>

  export default {
    components: {},
    data() {
      return {
        dialogVisible: false,
        contentLoading: false,
        submitLoading: false,
        saveDisabled: false,
        multipleSelection: [],
        templateId: 0,
        faultId: "",
        faultType: 0,
        solutions: [],
        row: "",
      };
    },
    computed: {
      title() {
        return this.$t("common.edit") + " " + this.$t("faultTemplate.solution");
      },
    },
    methods: {
      open(row) {
        console.log(row);
        this.dialogVisible = true;
        this.row = row;
        this.solutions = row.solutionSettings !== null ? row.solutionSettings : [];
      },
      onDialogClose() {
        this.saveDisabled = false;
        this.contentLoading = false;
        this.solutions = [];
      },
      handleSubmit() {
        this.row.solutionSettings = this.solutions;
        this.submitLoading = false;
        this.dialogVisible = false;
        this.$message.success(this.$t("common.tip.saveSuccess"));
      },
      deleteRow(index) {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          this.solutions.splice(index, 1);
        });
      },
      handleSelectionChange(val) {
        this.multipleSelection = val;
      },
      handleDeleteClick() {
        this.$confirm(this.$t("common.confirmTip") + "?", this.$t("common.tips"), {
          confirmButtonText: this.$t("common.confirm"),
          cancelButtonText: this.$t("common.cancel"),
          type: "warning",
        }).then(() => {
          for (let item of this.multipleSelection) {
            for (let i = 0; i < this.solutions.length; i++) {
              if (item.index === this.solutions[i].index) {
                this.solutions.splice(i, 1);
                i--;
                break;
              }
            }
          }
        });
      },
      handleAddClick() {
        let solution = {
          index: this.solutions.length,
          faultType: this.faultType,
          templateId: this.templateId,
          faultId: this.faultId,
          reason: "",
          solution: "",
        };
        this.solutions.push(solution);
      },
    },
  };
</script>

<style lang="scss" scoped>
</style>
